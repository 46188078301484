<template>
  <div class="container-fluid pt-3">
    <Modal @close="toggleDeleteModal" :modalActive="delteActiveModal">
      <template v-slot:header>{{ t("tour.delete") }}</template>
      <template v-slot:body>
        <p>{{ t("tour.delete_confirm_inquire") }}</p>
      </template>
      <template v-slot:footer>
        <button @click="toggleDeleteModal" type="button" class="btn btn-secondary">{{ t("general.cancel") }}</button>
        <button @click="deleteTour" type="button" class="btn btn-danger">{{ t("general.remove") }}</button>
      </template>
    </Modal>
    <Modal @close="addModal = !addModal" :modalActive="addModal">
      <template v-slot:header>{{ t("general.add") }} {{ t("general.tour") }}</template>
      <template v-slot:body>
        <div class="mb-3">
          <label for="tour_name_new" class="form-label">{{ t("general.name") }}</label>
          <input v-model="newTour.name" type="text" class="form-control" id="tour_name_new" />
        </div>
        <div class="mb-3">
          <label for="tour_start_at_new" class="form-label">{{ t("general.start_at") }}</label>
          <input v-model="newTour.start_at" type="date" class="form-control" id="tour_start_at_new" />
        </div>
        <div class="mb-3">
          <label for="tour_end_at_new" class="form-label">{{ t("general.end_at") }}</label>
          <input v-model="newTour.end_at" type="date" class="form-control" id="tour_end_at_new" />
        </div>
        <div class="mb-3">
          <label for="tour_area_new" class="form-label">{{ t("general.area") }}</label>
          <input v-model="newTour.area" type="text" class="form-control" id="tour_area_new" />
          <div v-if="autocompleteArea.length > 0" class="autocomplete-container">
            <div
              v-for="(area, index) in autocompleteArea"
              :key="index"
              @click="setArea(area)"
              class="autocomplete-item"
            >
              {{ area }}
            </div>
          </div>
        </div>
        <div class="mb-3">
          <label for="tour_comment_new" class="form-label">{{ t("general.comment") }}</label>
          <textarea v-model="newTour.comment" class="form-control" id="tour_comment_new" />
        </div>
      </template>
      <template v-slot:footer>
        <button @click="addModal = !addModal" type="button" class="btn btn-secondary">{{ t("general.cancel") }}</button>
        <button @click="addTour" type="button" class="btn btn-success">{{ t("general.save") }}</button>
      </template>
    </Modal>
    <Modal @close="linkModal = !linkModal" :modalActive="linkModal" modalCssClass="modal-xl">
      <template v-slot:header>{{ t("general.link_tour") }}</template>
      <template v-slot:body>
        <div class="gws_control_bar d-lg-flex justify-content-between align-items-center gap-2 p-2 mb-2">
          <div class="d-lg-flex align-items-center gap-2">
            <!-- search input -->
            <div class="form-floating mb-2 mb-lg-0">
              <input type="text" class="form-control" id="searchName" v-model="filterTourName" placeholder="Name" />
              <label for="search">Name</label>
            </div>
          </div>
        </div>
        <div class="gws_address_search_xxx">
          <!-- list tours -->
          <div class="card-group gws_card-group" v-if="alltours">
            <template v-for="tour in alltours" :key="tour.id">
              <div
                class="card gws_search_results mb-sm-2 text-dark me-2"
                v-if="filterTourName === null || tour.name.toLowerCase().includes(filterTourName.toLowerCase())"
              >
                <div class="card-body">
                  <div class="card-text">
                    <dl>
                      <dt>{{ t("general.name") }}</dt>
                      <dd>{{ tour.name }}</dd>
                      <dt>{{ t("general.start_at") }}</dt>
                      <dd>{{ tour.start_at }}</dd>
                      <dt>{{ t("general.end_at") }}</dt>
                      <dd>{{ tour.end_at }}</dd>
                    </dl>
                    <!-- <div class="d-flex flex-wrap">
                      <button @click="linkTour(tour)" type="button" class="btn btn-success mr-2">
                        {{ t("general.link") }}
                      </button>
                    </div> -->
                  </div>
                </div>
                <div class="card-footer text-center">
                  <button @click="linkTour(tour)" type="button" class="btn btn-success mr-2">
                    {{ t("general.link") }}
                  </button>
                </div>
              </div>
            </template>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <button @click="linkModal = !linkModal" type="button" class="btn btn-secondary">
          {{ t("general.cancel") }}
        </button>
      </template>
    </Modal>
    <div class="gws_content_container p-3">
      <!-- title -->
      <div class="row g-3">
        <div class="col-md-12">
          <h1 class="text-center mb-0">{{ t("nav.tours") }}</h1>
          <div class="subtitle-name mb-3 text-center">{{ displayName }}</div>
          <!-- {{ address.id }} -->
        </div>
      </div>
      <!-- nav -->
      <div class="row g-3">
        <div class="col-md-12">
          <AddressNavigation :categories="address.categories" :id="$route.params.id"></AddressNavigation>
        </div>
      </div>
      <div>
        <!-- add new -->
        <div class="gws_control_bar d-lg-flex justify-content-center align-items-center p-2 mt-3 mb-2">
          <button
            @click="addModal = !addModal"
            type="button"
            class="d-flex flex-row align-items-center justify-content-center btn btn-success p-0 px-2 me-2"
            title="Erstellen"
          >
            <svg class="" width="32" height="32" fill="currentColor">
              <use xlink:href="/assets/img/bootstrap-icons.svg#plus"></use>
            </svg>
            <div>{{ t("general.add") }}</div>
          </button>
          <button
            @click="linkModal = !linkModal"
            type="button"
            class="d-flex flex-row align-items-center justify-content-center btn btn-success p-0 px-2"
            title="Erstellen"
          >
            <svg class="" width="32" height="32" fill="currentColor">
              <use xlink:href="/assets/img/bootstrap-icons.svg#link"></use>
            </svg>
            <div style="text-transform: capitalize">{{ t("general.link") }}</div>
          </button>
        </div>
        <template v-for="tour in tours" :key="tour.tour.id">
          <div class="row">
            <div class="col">
              <div class="card my-2">
                <div class="card-body">
                  <div class="card-text">
                    <div class="row g-3">
                      <div class="col-xl-2 col-sm-6">
                        <label :for="'tour_name_' + tour.tour.id" class="form-label">{{ t("general.name") }}</label>
                        <input
                          v-model="tour.tour.name"
                          type="text"
                          class="form-control"
                          :id="'tour_name_' + tour.tour.id"
                        />
                      </div>
                      <div class="col-xl-2 col-sm-3">
                        <label :for="'tour_start_at_' + tour.tour.id" class="form-label">{{
                          t("general.start_at")
                        }}</label>
                        <input
                          v-model="tour.tour.start_at"
                          type="date"
                          class="form-control"
                          :id="'tour_start_at_' + tour.tour.id"
                        />
                      </div>
                      <div class="col-xl-2 col-sm-3">
                        <label :for="'tour_end_at_' + tour.tour.id" class="form-label">{{ t("general.end_at") }}</label>
                        <input
                          v-model="tour.tour.end_at"
                          type="date"
                          class="form-control"
                          :id="'tour_end_at_' + tour.tour.id"
                        />
                      </div>
                      <div class="col-xl-2 col-sm-6">
                        <label :for="'tour_area_' + tour.tour.id" class="form-label">{{ t("general.area") }}</label>
                        <input
                          v-model="tour.tour.area"
                          type="text"
                          class="form-control"
                          :id="'tour_area_' + tour.tour.id"
                        />
                      </div>
                      <div class="col-xl-4 col-sm-6">
                        <label :for="'tour_comment_' + tour.tour.id" class="form-label">{{
                          t("general.comment")
                        }}</label>
                        <textarea v-model="tour.comment" class="form-control" :id="'tour_comment_' + tour.id" />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 border-top mt-3 pt-3 d-flex justify-content-center align-items-center">
                        <button
                          @click="save(tour)"
                          type="button"
                          class="btn btn-success btn-icon me-2"
                          :title="t('general.save')"
                        >
                          <svg class="bi" width="20" height="20" fill="currentColor">
                            <use xlink:href="/assets/img/bootstrap-icons.svg#check"></use>
                          </svg>
                          {{ t("general.save") }}
                        </button>
                        <button
                          @click="toggleDeleteModal(tour.id)"
                          type="button"
                          class="btn btn-danger btn-icon me-2"
                          :title="t('general.delete')"
                        >
                          <svg class="bi" width="20" height="20" fill="currentColor">
                            <use xlink:href="/assets/img/bootstrap-icons.svg#x"></use>
                          </svg>
                          {{ t("general.delete") }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n/index";
import { useApiQuery } from "@/composables/useApiQueries";
import axios from "axios";
import storeConfig from "../../store/config";
import { useToast } from "vue-toastification";
import Modal from "@/components/Modal.vue";
import AddressNavigation from "@/components/navigation/Address.vue";
// import CardText from "@/components/addresses/CardText.vue";
// import StageCardText from "@/components/stages/CardText.vue";
// import AddressesLinkSearch from "@/components/addresses/AddressesLinkSearch.vue";
export default {
  name: "tours",
  components: {
    Modal,
    AddressNavigation,
    // CardText,
    // StageCardText,
    // AddressesLinkSearch,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const { t } = useI18n();
    const toast = useToast();
    const currentUser = computed(() => store.getters.user);
    const { result: address, query: getAddress } = useApiQuery({ url: "addresses/" + route.params.id, defaultVal: [] });
    const { result: areas, query: getAreas } = useApiQuery({ url: "search/tour/areas", defaultVal: [] });
    const { result: tours, query: getTours } = useApiQuery({
      url: "search/artisttour/byartist",
      defaultVal: [],
      params: {
        params: {
          artist_id: route.params.id,
        },
      },
      pfn: function (data) {
        return data.map((tour) => {
          if (tour.tour.start_at) {
            tour.tour.start_at = tour.tour.start_at.substr(0, 10);
          }
          if (tour.tour.end_at) {
            tour.tour.end_at = tour.tour.end_at.substr(0, 10);
          }
          return tour;
        });
      },
    });
    const { result: alltours, query: getAllTours } = useApiQuery({ url: "tour", defaultVal: [] });
    // const { result: users, query: getUsers } = useApiQuery({
    //   url: "user",
    //   defaultVal: [],
    // });

    // const activeUsers = computed(() => {
    //   return users.value.filter((user) => user.status === "active");
    // });

    const addModal = ref(false);
    const linkModal = ref(false);
    const newTour = ref({
      name: null,
      start_at: null,
      end_at: null,
      area: null,
      comment: null,
    });

    const autocompleteArea = computed(() => {
      if (newTour.value.area === null || newTour.value.area.length < 2) {
        return [];
      }
      if (areas.value.find((area) => area === newTour.value.area)) {
        return [];
      }
      return areas.value.filter((area) => {
        if (area.toLowerCase().includes(newTour.value.area.toLowerCase())) {
          return area;
        }
      });
    });

    const setArea = (area) => {
      newTour.value.area = area;
    };

    async function addTour() {
      const artisttourData = {
        comment: newTour.value.comment,
        created_by: currentUser.value.id,
        updated_by: currentUser.value.id,
        artist_id: route.params.id,
      };
      const tourData = {
        name: newTour.value.name,
        start_at: newTour.value.start_at,
        end_at: newTour.value.end_at,
        area: newTour.value.area,
        created_by: currentUser.value.id,
        updated_by: currentUser.value.id,
      };
      addModal.value = false;
      try {
        await store.dispatch("addLoading", 1);
        let result = await axios.post(storeConfig.apiBaseUrl + "tour", tourData);
        artisttourData.tour_id = result.data.id;
        result = await axios.post(storeConfig.apiBaseUrl + "artisttour", artisttourData);
        if (result.data.id) {
          toast.success(t("general.tour") + " " + t("general.successfully") + " " + t("general.added") + ".");
          await getTours();
          newTour.value.name = null;
          newTour.value.start_at = null;
          newTour.value.end_at = null;
          newTour.value.area = null;
          newTour.value.comment = null;
        }
      } catch (error) {
        if (error.response.status == 401) {
          store.dispatch("logout");
          router.push({ name: "Login" });
        }
      } finally {
        await store.dispatch("addLoading", -1);
      }
      // console.log(data);
    }

    const filterTourName = ref(null);
    async function linkTour(tour) {
      // console.log(tour);
      linkModal.value = !linkModal.value;
      const artisttourData = {
        created_by: currentUser.value.id,
        updated_by: currentUser.value.id,
        artist_id: route.params.id,
        tour_id: tour.id,
      };
      try {
        await store.dispatch("addLoading", 1);
        let result = await axios.post(storeConfig.apiBaseUrl + "artisttour", artisttourData);
        if (result.data.id) {
          toast.success(t("general.tour") + " " + t("general.successfully") + " " + t("general.added") + ".");
          await getTours();
        }
      } catch (error) {
        if (error.response.status == 401) {
          store.dispatch("logout");
          router.push({ name: "Login" });
        }
      } finally {
        await store.dispatch("addLoading", -1);
      }
    }

    async function save(tour) {
      // console.log(tour, tour.comment);
      const artisttourData = {
        comment: tour.comment,
      };
      const tourData = {
        name: tour.tour.name,
        start_at: tour.tour.start_at,
        end_at: tour.tour.end_at,
        area: tour.tour.area,
        updated_by: currentUser.value.id,
      };
      try {
        await store.dispatch("addLoading", 1);
        await axios.put(storeConfig.apiBaseUrl + "artisttour/" + tour.id, artisttourData);
        await axios.put(storeConfig.apiBaseUrl + "tour/" + tour.tour.id, tourData);
        toast.success(t("general.tour") + " " + t("general.successfully") + " " + t("general.edited") + ".");
        getAll();
      } catch (error) {
        if (error.response.status == 401) {
          store.dispatch("logout");
          router.push({ name: "Login" });
        }
      } finally {
        await store.dispatch("addLoading", -1);
      }
    }

    async function deleteTour() {
      // console.log(selectedTour.value);
      if (selectedTour.value) {
        try {
          await store.dispatch("addLoading", 1);
          await axios.delete(storeConfig.apiBaseUrl + "artisttour/" + selectedTour.value);
          toggleDeleteModal();
          toast.success(t("general.tour") + " " + t("general.successfully") + " " + t("general.removed") + ".");
          await getTours();
        } catch (error) {
          if (error.response && error.response.status == 401) {
            store.dispatch("logout");
            router.push({ name: "Login" });
          }
        } finally {
          await store.dispatch("addLoading", -1);
        }
      }
    }

    const selectedTour = ref(null);
    const delteActiveModal = ref(false);
    const toggleDeleteModal = (id) => {
      if (id) {
        selectedTour.value = id;
      }
      delteActiveModal.value = !delteActiveModal.value;
    };

    async function getAll() {
      await Promise.all([getAddress(), getTours(), getAreas(), getAllTours()]);
    }

    getAll();

    return {
      t,
      tours,
      address,
      currentUser,
      displayName: computed(() => {
        if (address.value.name) {
          return address.value.name;
        } else if (address.value.forename && address.value.surname) {
          return address.value.forename + " " + address.value.surname;
        } else if (address.value.forename) {
          return address.value.forename;
        } else if (address.value.surname) {
          return address.value.surname;
        } else {
          return "";
        }
      }),
      areas,
      addModal,
      newTour,
      addTour,
      save,
      toggleDeleteModal,
      delteActiveModal,
      deleteTour,
      autocompleteArea,
      setArea,
      // activeUsers,
      linkModal,
      alltours,
      linkTour,
      filterTourName,
    };
  },
};
</script>
